// Import Poppins Google fonts 300, 400, 500, 600
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
::-webkit-scrollbar {
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: #0a0911;
}
::-webkit-scrollbar-thumb {
  background-color: lighten($color: #1d1b31, $amount: 20);
  border-radius: 100vw;
}
::-webkit-scrollbar-thumb:hover {
  background-color: lighten($color: #1d1b31, $amount: 15);
}
.background_primary{
  // background-color: #00CFFF;
  background-color: #1c75bc;
}
.background_secondary{
  background-color: #11101d;
  // background-color: #afabd7;
}
.color_primary{
  // color: #00CFFF;
  color: #1c75bc;
}
.color_secondary{
  color: #11101d;
}
.font_report_icon{
  font-size: 60px;
}
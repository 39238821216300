.table-hover tbody tr:hover td {
  background-color: rgba(24, 226, 61, 0.2);
}
.expand{
  cursor: pointer;
}

 tr.template{
  margin-left: 48px;
}

.sidebar {
  position: relative;
  min-height: 930px;
  max-height: fit-content;
  padding: 5px;
  width: 305px;
  background: #11101d;
  z-index: 100;
  transition: all 0.5s ease;
}
.sidebar.close {
  width: 78px;
}
.sidebar .logo-details {
  // background-color: #958e8e;
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
}
.sidebar .logo-details img {
  height: 60px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: white;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav_links {
  padding-top: 30px;
}
.sidebar.close .nav_links {
  overflow: visible;
}
.sidebar .nav_links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .nav_links li:hover  {
  // background-color: #00CFFF;
  background-color: #1c75bc;
  // background-color: #00CFFF;
}
.sidebar .nav_links  .nav-item.active  {
  // background-color: #00CFFF;
  background-color: #1c75bc;
}

.sidebar .nav_links li .iocn_link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav_links li .iocn_link {
  display: block;
}
.sidebar .nav_links li i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav_links li.show_menu i.arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav_links i.arrow {
  display: none;
}
.sidebar .nav_links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav_links li a .link_name {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
.sidebar.close .nav_links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav_links li .sub_menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background-color: #1d1b31;
  overflow-x: auto;
  display: none;
}
.sidebar .nav_links li.show_menu .sub_menu {
  display: block;
}
.sidebar .nav_links li .sub_menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 10px ;
  white-space: nowrap;

  transition: all 0.3 ease;
}
.sidebar .nav_links li .sub_menu a:hover {
  opacity: 1;
}
.sidebar.close .nav_links li .sub_menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: block;
}
.sidebar.close .nav_links li:hover .sub_menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav_links li .sub_menu .link_name {
  display: none;
}
.sidebar .nav_links li .sub_menu .link_name {
  display: none;
}
.sidebar.close .nav_links li .sub_menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav_links li .sub_menu .blank {
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
  transition: 0s;
}
.sidebar .nav_links li:hover .sub_menu .blank {
  top: 50%;
  transform: translateX(-50%);
}
.sidebar .profile_details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  transition: all 0.4s ease;
}
.sidebar.close .profile_details {
  width: 78px;
}
.sidebar .profile_details .profile_content {
  display: flex;
  align-items: center;
}
.sidebar .profile_details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 14px;
  background: #1d1b31;
  padding: 10px;
}
.sidebar .profile_details .profile_name,
.sidebar .profile_details .job {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar.close .profile_details .profile_name,
.sidebar.close .profile_details .job,
.sidebar.sidebar.close .profile_details i {
  display: none;
}
.sidebar .profile_details .job {
  font-size: 12px;
}
.page_content{
  position: absolute;
  top: 0;
  left: 305px;
  background-color: #eee;
  height: 930px;
  overflow-y: scroll;
  width: calc(100% - 305px);
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
}
.nav_header {
  background-color: #eee;
  height: 60px;
  width: 100%;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
}
.nav_header .burger_icon{
  color: #11101d;
  font-size: 35px;
  margin: 0 15px;
  cursor: pointer;
}
.nav_header .text {
  color: #11101d;
  font-size: 26px;
  font-weight: 600;
}
.sidebar.close ~ .page_content {
  left: 78px;
  width: calc(100% - 78px);
}
.sidebar .nav_links li.show_menu ~ .page_content{
  left: 400px;
}


.custom-dropdown-button .dropdown-toggle {
  color: white !important;
}
